export default function () {
	if (typeof google === 'object' && typeof google.maps === 'object') {
		$('.google-map').each(function () {
			var map = initMap($(this));
		});
	}
}

function initMap($el) {
	var $markers = $el.find('.marker');
	var mapArgs = {
		zoom: $el.data('zoom') || 16,
		mapTypeId: google.maps.MapTypeId.ROADMAP,
	};
	var map = new google.maps.Map($el[0], mapArgs);
	map.markers = [];
	$markers.each(function () {
		initMarker($(this), map);
	});
	centerMap(map);
	return map;
}
function initMarker($marker, map) {
	var lat = $marker.data('lat');
	var lng = $marker.data('lng');
	var latLng = {
		lat: parseFloat(lat),
		lng: parseFloat(lng),
	};

	var icon = {
		url: 'data:image/svg+xml;utf-8, <svg viewBox="0 0 24 34" xmlns="http://www.w3.org/2000/svg" width="24" height="34" background-color="%23ffffff00"><path d="M0 12C0 21.7 12 33.7 12 33.7 12 33.7 24 21.7 24 12 24 5.4 18.6 0 12 0 5.4 0 0 5.4 0 12Z" fill="%23941C81"/><path d="M7.3 12C7.3 9.4 9.4 7.3 12 7.3 14.6 7.3 16.7 9.4 16.7 12 16.7 14.6 14.6 16.7 12 16.7 9.4 16.7 7.3 14.6 7.3 12Z" fill="%23fff"/></svg>',
	};
	var marker = new google.maps.Marker({
		position: latLng,
		map: map,
		icon: icon,
	});
	map.markers.push(marker);
	if ($marker.html()) {
		var infowindow = new google.maps.InfoWindow({
			content: $marker.html(),
		});
		google.maps.event.addListener(marker, 'click', function () {
			infowindow.open(map, marker);
		});
	}
}
function centerMap(map) {
	var bounds = new google.maps.LatLngBounds();
	map.markers.forEach(function (marker) {
		bounds.extend({
			lat: marker.position.lat(),
			lng: marker.position.lng(),
		});
	});
	if (map.markers.length == 1) {
		map.setCenter(bounds.getCenter());
	} else {
		map.fitBounds(bounds);
	}
}
