// Import Swiper and modules
import Swiper, { Navigation, Pagination, EffectFade, Autoplay } from 'swiper';

// Install modules
Swiper.use([Navigation, Pagination, EffectFade, Autoplay]);

export function animatedSlider() {
	const container = document.getElementById('animated_container');
	if (!container) return false;
	const animation = {
		container: container,
		path: container.dataset.animationFile,
		renderer: 'svg',
		loop: false,
		autoplay: false,
		name: 'Anglo Scottish Animation',
	};
	let anim = null;
	let totalSlides = 0;
	let totalFrames = 0;
	let framesPerAnim = 0;

	new Swiper('#animated_slider', {
		effect: 'fade',
		fadeEffect: {
			crossFade: true,
		},
		autoplay: {
			delay: 4500,
			disableOnInteraction: false,
		},
		pagination: {
			el: '.swiper-pagination',
			type: 'bullets',
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		on: {
			init(swiper) {
				anim = lottie.loadAnimation(animation);
				totalSlides = swiper.slides.length;

				anim.addEventListener('DOMLoaded', function () {
					totalFrames = anim.totalFrames;
					framesPerAnim = totalFrames / totalSlides;
					playAnimation(anim, framesPerAnim, swiper.realIndex);
				});
			},
			slideChange: function (swiper) {
				playAnimation(anim, framesPerAnim, swiper.realIndex);
			},
		},
	});
}

function playAnimation(animation, framesToPlay, index) {
	animation.playSegments([framesToPlay * index, framesToPlay * (index + 1)], true);
}
