(function () {
	/* Register the buttons */
	console.log('changed');
	tinymce.create('tinymce.plugins.MyButtons', {
		init: function (editor, url) {
			editor.addButton('animated_text', {
				image: '../wp-content/themes/inspired-anglo/assets/img/wysiwyg/ab-testing.svg',
				title: 'Insert Button',
				onclick: function () {
					editor.windowManager.open({
						title: 'Select Group for animated content',
						body: [
							{
								type: 'listbox',
								name: 'animated_content',
								label: 'Content Group',
								values: animation_groups,
							},
						],
						onsubmit: function (e) {
							e.preventDefault();
							editor.insertContent(`[animated_content group_id="${e.data.animated_content}"]`);
							editor.windowManager.close();
						},
					});
				},
			});
			editor.addButton('btn_shortcode', {
				image: '../wp-content/themes/inspired-anglo/assets/img/wysiwyg/button.svg',
				title: 'Insert Button',
				onclick: function () {
					editor.windowManager.open({
						title: 'Insert Button',
						body: [
							{
								type: 'textbox',
								name: 'url',
								label: 'URL',
								value: '',
							},
							{
								type: 'textbox',
								name: 'link_text',
								label: 'Button Text',
								value: '',
							},
							{
								type: 'listbox',
								name: 'color',
								label: 'Button Color',
								values: btns,
								value: btns[0]['value'], // Sets the default
							},
							{
								type: 'checkbox',
								name: 'target',
								label: 'Open link in a new tab',
								value: '',
							},
						],
						onsubmit: function (e) {
							e.preventDefault();
							if (e.data.url == '' || e.data.url == '') {
								alert('URL and Button Text are both required to create a button');
							} else {
								let content = '';
								content += e.data.url != '' ? `href="${e.data.url}" ` : '';
								content += e.data.target != '' ? `target="_blank" ` : '';
								content += `class="btn ${e.data.color}"`;
								editor.insertContent(`<a ${content} title="${e.data.link_text}">${e.data.link_text}</a>`);
								editor.windowManager.close();
							}
						},
					});
				},
			});
		},
		createControl: function (n, cm) {
			return null;
		},
	});
	/* Start the buttons */
	tinymce.PluginManager.add('my_button_script', tinymce.plugins.MyButtons);
})();
