// Import Swiper and modules
import Swiper, { Navigation, Pagination, EffectFade, Autoplay, Scrollbar, Grid } from 'swiper';

// Install modules
Swiper.use([Navigation, Pagination, EffectFade, Autoplay, Scrollbar, Grid]);

export function initSwiper() {
	const initSwiper = document.querySelectorAll('[data-swiper]');
	initSwiper.forEach((element) => {
		let params = JSON.parse(element.dataset.swiper);
		new Swiper(element, params);
	});
}

export function howItWorksSlider() {
	let tabs = document.querySelector('.how-it-works [data-swiper-tabs]');
	if (!tabs) return false;

	tabs = JSON.parse(tabs.dataset.swiperTabs);
	new Swiper('.how-it-works-slider', {
		pagination: {
			el: '.how-it-works .swiper-pagination',
			clickable: 'true',
			type: 'bullets',
			bulletClass: 'swiper-pagination-tabs',
			renderBullet: function (index, className) {
				return `<span class="${className}">Step ${index + 1}: ${tabs[index]}</span>`;
			},
		},
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		on: {
			paginationUpdate: function (swiper, paginationEl) {
				let activeTab = paginationEl.querySelector('.swiper-pagination-bullet-active');
				let indicator = paginationEl.nextElementSibling;
				if (indicator) {
					let line = indicator.querySelector('.line');
					setLineStyle(activeTab, line);
				}
			},
		},
	});
}

function setLineStyle(tab, line) {
	line.classList.add('visible');
	line.style.left = tab.offsetLeft + 'px';
	line.style.width = tab.clientWidth + 'px';
}
