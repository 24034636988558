import Collapse from 'bootstrap/js/dist/collapse';
import Tab from 'bootstrap/js/dist/tab';
import Modal from 'bootstrap/js/dist/modal';

import hcOffcanvasNav from 'hc-offcanvas-nav';

import { lazyloadVideoElement, lazyloadiFrameElement, backgroundImageLazyLoad } from './utilities/lazyload';
import { navigation, bodyOverlay, navigationFunctions, animateNav } from './components/nav';
import { initSwiper, howItWorksSlider } from './components/sliders';
import googleMaps from './components/map';
import animatedContent from './components/animatedContent';
import contactBar from './components/contactBar';
import { quoteSelector, quoteBuilder } from './components/quoteBuilder';

import { animatedSlider } from './components/animatedBanner';

// Comment Test
console.log('changed');
window.addEventListener('load', function () {
	// Utilities
	backgroundImageLazyLoad();
	lazyloadiFrameElement();
	lazyloadVideoElement();

	// Navigation
	navigationFunctions(navigation);
	bodyOverlay();
	animateNav();

	// Componenets
	contactBar();

	//Sliders
	initSwiper();
	howItWorksSlider();
	animatedSlider();

	// Google Maps
	googleMaps();

	// Animated Banner Content
	animatedContent();

	// Quote Builder functions
	quoteSelector();
	quoteBuilder();

	$('#mobile-search-icon').on('click', function () {
		$('#mobile-search').slideToggle();
	});
});

window.onscroll = function () {
	contactBar();
	animateNav();
};
