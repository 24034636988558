export const navigation = new hcOffcanvasNav('#main-nav', {
	disableAt: 992,
	customToggle: '.toggle',
	navTitle: false,
	levelSpacing: 0,
	levelTitles: false,
	levelTitleAsBack: false,
	bodyInsert: 'append',
	insertClose: false,
	width: '100%',
	labelBack: '',
});

export function animateNav() {
	if (window.pageYOffset > 55) {
		$('header').addClass('sticky');
	} else {
		$('header').removeClass('sticky');
	}
}

export function bodyOverlay() {
	$('#main-nav li.menu-item-has-children').on('mouseover', function (e) {
		$('body').addClass('overlay');
	});

	$('#main-nav li.menu-item-has-children').on('mouseout', function (e) {
		$('body').removeClass('overlay');
	});
}

export function navigationFunctions(navigation) {
	// navigation.on('toggle', (e, settings) => {
	// 	$('.hamburger.main').toggleClass('open');
	// });
}
