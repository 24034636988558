export default function () {
	const animatedContent = document.querySelectorAll('.animated_content');
	if (!animatedContent) return false;

	animatedContent.forEach((element) => {
		let fromSelector = element.querySelector('[data-from]');
		let toSelector = element.querySelector('[data-to]');
		const fromElements = JSON.parse(fromSelector.dataset.from);
		const toElements = JSON.parse(toSelector.dataset.to);

		fromElements.forEach(function (arrayItem) {
			arrayItem.width = getTextWidth(arrayItem.name, getComputedStyle(element)) + 'px';
		});

		toElements.forEach(function (arrayItem) {
			arrayItem.width = getTextWidth(arrayItem.name, getComputedStyle(element)) + 'px';
		});

		let counter = 0;
		setInterval(() => {
			counter = counter == fromElements.length - 1 ? 0 : counter + 1;
			fromSelector.classList.add('opacity-0');
			toSelector.classList.add('opacity-0');

			setTimeout(() => {
				fromSelector.innerHTML = fromElements[counter].name;
				fromSelector.style.width = fromElements[counter].width;

				toSelector.innerHTML = toElements[counter].name;
				toSelector.style.width = toElements[counter].width;

				fromSelector.classList.remove('opacity-0');
				toSelector.classList.remove('opacity-0');
			}, 500);
		}, 3000);
	});
}

function getTextWidth(text, fontStyling) {
	if (fontStyling.textTransform == 'uppercase') text = text.toUpperCase();

	const canvas = document.createElement('canvas');
	const context = canvas.getContext('2d');
	context.font = fontStyling.font || getComputedStyle(document.body).font;
	return context.measureText(text).width;
}
