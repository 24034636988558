// Quote selector module function
export function quoteSelector() {
	const quoteSelect = document.getElementById('quoteSelector');
	if (!quoteSelect) return false;
	const getBtn = document.getElementById('getQuote');

	quoteSelect.addEventListener('change', function () {
		if (this.value) {
			getBtn.disabled = false;
		} else {
			getBtn.disabled = true;
		}
	});
	getBtn.addEventListener('click', function () {
		window.location.href = quoteSelect.value;
	});
}

export function quoteBuilder() {
	const quoteBuilder = document.getElementById('quoteBuilder');
	if (!quoteBuilder) return false;

	const form = quoteBuilder.querySelector('.quote-form_wrapper');
	const yearsInput = quoteBuilder.querySelector('[name="year"]');
	const borrowInput = quoteBuilder.querySelector('[name="borrowAmount"]');

	calculateResults(quoteBuilder, form);

	yearsInput.addEventListener('input', function () {
		calculateResults(quoteBuilder, form);
	});
	borrowInput.addEventListener('input', function () {
		calculateResults(quoteBuilder, form);
	});
}

// Based on formular https://www.calculatorsoup.com/calculators/financial/apr-calculator-basic.php
function calculateResults(quoteBuilder, form = null) {
	// UI Variables - Inputs
	const loanAmount = quoteBuilder.querySelector('[name="borrowAmount"]');
	const interestAmount = quoteBuilder.dataset.quoteApr;
	const yearsAmount = quoteBuilder.querySelector('[name="year"]');

	// UI Variables - Results
	const monthlyPayment = quoteBuilder.querySelector('.instalments');
	const totalPayment = quoteBuilder.querySelector('.totalAmount');
	const totalInterest = quoteBuilder.querySelector('.interestAmount');
	const totalMonths = quoteBuilder.querySelector('.totalMonths');

	// Calculation Variables
	const principle = parseFloat(loanAmount.value);
	const calculatedInterest = parseFloat(interestAmount) / 100 / 12;
	const calculatedPayments = parseFloat(yearsAmount.value) * 12;

	// Calculate Montly Payments
	const x = Math.pow(1 + calculatedInterest, calculatedPayments);
	const monthly = (principle * x * calculatedInterest) / (x - 1);

	// Output Result
	if (isFinite(monthly)) {
		const monthlyPaymentData = '£' + monthly.toFixed(2);
		const totalPaymentData = '£' + (monthly * calculatedPayments).toFixed(2);
		const totalInterestData = '£' + (monthly * calculatedPayments - principle).toFixed(2);

		monthlyPayment.innerHTML = monthlyPaymentData;
		totalPayment.innerHTML = totalPaymentData;
		totalInterest.innerHTML = totalInterestData;
		totalMonths.innerHTML = calculatedPayments;

		if (form) {
			const formData = {
				amountBorrowed: '£' + loanAmount.value,
				timePeriod: yearsAmount.value,
				interest: totalInterestData,
				amountPayable: monthlyPaymentData,
				monthlyPayments: totalPaymentData,
			};
			updateFormFields(form, formData);
		}
	} else {
		// alert(`Please check your numbers`);
	}
}

function updateFormFields(form, formData) {
	const amountBorrowed = form.querySelector('.amount-borrowed input');
	const timePeriod = form.querySelector('.time-period input');
	const interest = form.querySelector('.interest input');
	const amountPayable = form.querySelector('.amount-payable input');
	const monthlyPayments = form.querySelector('.monthly-payments input');

	if (!amountBorrowed || !timePeriod || !interest || !amountPayable || !monthlyPayments) return false;

	amountBorrowed.value = formData.amountBorrowed;
	timePeriod.value = formData.timePeriod;
	interest.value = formData.interest;
	amountPayable.value = formData.amountPayable;
	monthlyPayments.value = formData.monthlyPayments;
}

// This is what is used on https://www.carfinance247.co.uk/car-finance-calculator
function example() {
	var interestRate = 9.9 / 100,
		loanAmount = document.querySelector(`#loan-amount`).value,
		totalTerm = document.querySelector(`#years-amount`).value * 12,
		t = Math.pow(1 + interestRate, 1 / 12),
		r = Math.pow(t, totalTerm),
		monthlyRepayment = ((loanAmount + 0) * (1 - t) * (r / (1 - r))) / 10,
		totalRepayment = monthlyRepayment * totalTerm,
		totalCostOfCredit = totalRepayment - Math.abs(loanAmount);
}
